import axios from "axios";
const devEnv = process.env.NODE_ENV !== "production";

const API = axios.create({
  baseURL: `${
    devEnv ? process.env.REACT_APP_DEV_API : process.env.REACT_APP_PROD_API
  }`,
  withCredentials: true,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("accessToken")) {
    req.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
  }
  return req;
});
// Flag Orders
// http://localhost:9001/orders/all?skip=0&take=10&type=Buy
export const getFlagBuyUsers = (page = 0) =>
  API.get(
    `/orders/all?skip=${page * 8}&take=8&type=Buy&startAmount=${
      process.env.REACT_APP_START_AMOUNT
    }`
  );
export const getFlagSellUsers = (page = 0) =>
  API.get(
    `/orders/all?skip=${page * 8}&take=8&type=Sell&startAmount=${
      process.env.REACT_APP_START_AMOUNT
    }`
  );
// Authors
export const signUp = (formData) =>
  API.post("/auth/send-verification-code", formData);
export const signIn = (formData) => API.post("/auth/login", formData);
export const logout = () => API.post("/auth/logout");
export const changePassword = (data) =>
  API.post("/admin/change-password", data);
export const changeEmail = (data) => API.patch("/admin", data);

// orders related
export const getTransactions = (page = 0) =>
  API.get(`/admin/transactions/all?skip=${page}&take=2000`);

export const getOrders = (page = 0) =>
  API.get(`/admin/orders/all?skip=${page * 8}&take=8`);
export const getFilteredOrders = (page = 0, type, value) =>
  API.get(`/admin/orders/all?skip=${page * 8}&take=8&${type}=${value}`);

export const getAdmin = () => API.get("/admin");
export const getSubAdmin = (id) => API.get(`/admin/${id}`);
export const getReferralStats = ({ userId, address }) =>
  API.get(`/admin/user/referral-stats/?userId=${userId}&address=${address}`);
export const deleteSubAdmin = (id) => API.delete(`/admin/${id}`);
export const updateSubAdmin = ({ id, formData }) =>
  API.patch(`/admin/${id}`, formData);
export const getMerchantRequest = (page = 0) =>
  API.get(`/admin/all/merchant-request?skip=${page * 8}&take=8`);
export const getRejectedRequest = (orderId) =>
  API.get(`/admin/all/rejected-request?requestId=${orderId}`);
export const getSubAdmins = (page = 0) =>
  API.get(`/admin/all?skip=${page * 8}&take=8`);
export const getBonus = (page = 0) =>
  API.get(`/admin/bonus/all?skip=${page * 8}&take=8`);
export const getLevelBonus = (level) => API.get(`/admin/bonus/${level}`);
export const getAdminUsers = (page = 0) =>
  API.get(`/admin/users/all?skip=${page * 8}&take=8&status=Active`);
export const getAdminActiveLastUsers = (page = 0) =>
  API.get(`/admin/get-users/all?skip=${page * 8}&take=8&status=Active`);
export const getAdminInActiveLastUsers = (page = 0) =>
  API.get(`/admin/get-users/all?skip=${page * 8}&take=8&status=InActive`);
export const getAdminInActiveUsers = (page = 0) =>
  API.get(`/admin/users/all?skip=${page * 8}&take=8&status=InActive`);
export const getAdminBlockedUsers = (page = 0) =>
  API.get(`/admin/users/all?skip=${page * 8}&take=8&status=Blocked`);
export const adminRegister = (formData) =>
  API.post("/auth/admin-register", formData);
export const sentVerficationCode = (formData) =>
  API.post("/auth/send-verification-code", formData);

// Admin User
export const blockUser = (id) => API.post(`/admin/block-user/${id}`);
export const unblockUser = (id) => API.post(`/admin/unblock-user/${id}`);
export const getUserBySearch = (page = 0, text) =>
  API.get(`admin/users/all?skip=${page * 8}&take=8&name=${text}`);
export const getUser = (id) => API.get(`/admin/users/${id}`);
export const getUserReview = (id) => API.get(`/reviews/users/${id}`);
export const updateUserDetails = (id, form) =>
  API.patch(`/admin/users/${id}`, form);
export const updatePartnerDetails = (id, form) =>
  API.patch(`/admin/partners/${id}`, form);

// Currency related
export const updateEnhancer = (id, form) =>
  API.patch(`/admin/currency-rate/${id}`, form);
export const getCurrency = () => API.get("/admin/currency-rate");
export const addCurrency = (form) => API.post("/admin/currency-rate", form);
export const deleteCurrency = (id, form) =>
  API.delete(`/admin/currency-rate/${id}`, { data: form });
export const getCurrencyPrice = (name) =>
  API.get(`/admin/currency/price/${name}`);

// Partner related
export const getPartners = (page = 0) =>
  API.get(`/purchases/partners?skip=${page * 8}&take=8`);
export const getPartnersTransactions = (page = 0, id) =>
  API.get(
    `/purchases/partners/bulk/transaction?skip=${page * 8}&take=8${
      id && `&partnerId=${id}`
    }`
  );
export const blockPartnerUser = (id) =>
  API.post(`/admin/users/${id}/bulk/status`);
export const changePartnerStatus = (id, form) =>
  API.put(`/purchases/partners/${id}`, form);
export const purchaseTransactionStatus = (id, form) =>
  API.put(`/purchases/bulk/${id}`, form);

// Purchase Requests
export const getPurchaseRequest = (page = 0, status) =>
  API.get(`/purchases?skip=${page * 8}&take=8${status && `&status=${status}`}`);
export const changePurchaseRequestStatus = (id, form) =>
  API.put(`/purchases/${id}`, form);

// upload photo
export const getFileUrl = (data) => API.post("/upload", data);

// QR code Management
export const getQrCode = () => API.get("/qr-codes");
export const addQrCode = (form) => API.post("/qr-codes", form);
export const updateQrCode = (id, form) => API.patch(`/qr-codes/${id}`, form);
export const deleteQrCode = (id, form) =>
  API.delete(`/qr-codes/${id}`, { data: form });
export const defaultQrCode = (id) => API.post(`/qr-codes/${id}`);

// Account Management
export const getAccount = () => API.get("/bank-accounts");
export const addAccount = (form) => API.post("/bank-accounts", form);
export const updateAccount = (id, form) =>
  API.patch(`/bank-accounts/${id}`, form);
export const deleteAccount = (id, form) =>
  API.delete(`/bank-accounts/${id}`, { data: form });
export const defaultAccount = (id) => API.post(`/bank-accounts/${id}`);

// merchants
export const getAllMerchantsInfo = (skip, searchText, active) =>
  API.get(
    `/admin/all/merchant?skip=${skip}&take=8${
      searchText && `&searchItem=${searchText}`
    }${active && `&active=${active}`}`
  );
export const getVerifiedMerchants = (skip) =>
  API.get(`/admin/all/merchant?status=accepted&skip=${skip}&take=8`);
export const getNewMerchants = (skip) =>
  API.get(`/admin/all/merchant?status=pending&skip=${skip}&take=8`);
export const getKycCompletedMerchants = (skip) =>
  API.get(`/admin/all/merchant-kyc?skip=${skip}&take=8`);
export const activateMerchnat = (id) =>
  API.put(`/admin/accept-merchant-registration/${id}`);

export const changeMerchantStatus = (id, path) =>
  API.put(`/admin/${path}/${id}`);
export const deActivateMerchant = (id) =>
  API.put(`/admin/reject-merchant-registration/${id}`);
export const blockUnblockMerchant = (id) =>
  API.put(`/admin/block-unblock-merchant/${id}`);
export const acceptUserKYCRequest = (id) =>
  API.put(`admin/accept-merchant-kyc/${id}`);
export const rejectMerchantKYCRequest = (id) =>
  API.put(`admin/reject-merchant-kyc/${id}`);
export const updateMerchnatDetails = (id, form) =>
  API.put(`admin/update-merchant/${id}`, form);

// Social post api
export const addSocial = (form) => API.post("/admin/social-id", form);
export const getSocial = (form) => API.post("/admin/get-social-id", form);

export const getPartnersList = (latitude, longitude) =>
  API.get(
    `/admin/all/partner-list?${`latitude=${latitude}`}&longitude=${longitude}`
  );

export const addtoPartner = (form) => API.post("/admin/partner-assign", form);

// New Admin API's
export const addtoBuyer = (form) => API.post("/admin/create-buy-request", form);
export const getSingleFactionAmount = () =>
  API.post("/purchase/node-fraction-price");
export const getStages = () => API.get("/admin/all/stages");
export const getSingleStage = (id) => API.get(`/admin/stage/${id}`);
export const getSingleFraction = (id) =>
  API.get(`/admin/stage-fractions/${id}?skip=0&take=2000`);
