import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import { reactIcons } from "../utils/icons";
import CustomNoRowsOverlay from "../utils/styles";
import * as api from "../redux/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const staticData = [
  {
    id: 1,
    time: "10:00 AM",
    index: "Index 1",
    receiveReward: "5 ETH",
  },
  {
    id: 2,
    time: "11:30 AM",
    index: "Index 2",
    receiveReward: "3 ETH",
  },
  {
    id: 3,
    time: "12:15 PM",
    index: "Index 3",
    receiveReward: "7 ETH",
  },
  {
    id: 4,
    time: "02:45 PM",
    index: "Index 4",
    receiveReward: "4 ETH",
  },
  {
    id: 5,
    time: "04:00 PM",
    index: "Index 5",
    receiveReward: "6 ETH",
  },
];
const ReportStepTwo = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [rowCountState, setRowCountState] = useState(0);

  const [rows, setRows] = useState(staticData);

  const columns = [
    {
      field: "time",
      headerName: "Time",
      type: "string",
      flex: 3,
    },
    {
      field: "index",
      headerName: "Index",
      type: "string",
      flex: 3,
    },
    {
      field: "receiveReward",
      headerName: "Receive Reward",
      type: "string",
      flex: 3,
    },
  ];

  useEffect(() => {
    const getSubAdminsData = async () => {
      setIsLoading(true);
      const response = await api.getSubAdmins(paginationModel.page);

      const { status, data } = response;
      if (status >= 200 && status < 300) {
        setIsLoading(false);
        setRowCountState(data.count);
        setRows(
          data.subAdmins.map((item, index) => {
            return { ...item, _id: 8 * paginationModel.page + index + 1 };
          })
        );
      } else {
        setIsLoading(false);
        toast.error(data.message);
      }
    };
    try {
      getSubAdminsData();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }

    setRowCountState((prevRowCountState) =>
      rowCountState !== undefined ? rowCountState : prevRowCountState
    );
  }, [paginationModel, rowCountState, setRowCountState]);
  return (
    <div className="w-full mt-6 data-grid">
      <div className="flex gap-2 mb-6 items-center">
        <button
          className="w-10 h-10 flex-center bg-theme-lighter rounded-full text-3xl"
          onClick={() => navigate(-1)}
        >
          {" "}
          {reactIcons.arrowleft}
        </button>
        <h4>Reports</h4>
      </div>

      <div className=" h-[600px] w-full">
        <DataGrid
          // loading={isLoading}
          rows={rows}
          getEstimatedRowHeight={() => 52}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          rowCount={rowCountState}
          pageSizeOptions={[8]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableColumnMenu
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
};

export default ReportStepTwo;
