import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  return Cookies.get(`${process.env.REACT_APP_NETWORK}accessToken`) ===
    "true" ? (
    children
  ) : (
    <Navigate to="/" />
  );
  // return Cookies.get(`${process.env.REACT_APP_NETWORK}accessToken`) ===
  //   'true' ? (
  //   children
  // ) : (
  //   <Navigate to="/" />
  // );
};

export default PrivateRoute;
