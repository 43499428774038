import React, { useState } from "react";
import { toast } from "react-toastify";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import * as api from "../../redux/api";
import { updateSubAdminPasswordValidation } from "../../utils/validation";
import Spinner from "../../components/loaders/Spinner";
import { isYupError, parseYupError } from "../../utils/Yup";
const initialState = {
  email: "",
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};
const Setting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [eye, setEye] = useState(false);
  const [eyeNew, setEyeNew] = useState(false);
  const [eyeNewNew, setEyeNewNew] = useState(false);

  const [form, setForm] = useState(initialState);
  const [formError, setFormError] = useState(initialState);
  console.log(formError, "formError");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };

  const handleChangeEmail = async () => {
    const payload = {
      email: form.email,
    };
    const res = await api.changeEmail(payload);
    const { status, data } = res;
    if (status) {
      console.log(res, "Res....");
      setForm({ email: "", oldPassword: "", newPassword: "" });
      toast.success("Email Update Successfully");
    } else {
      toast.error(data.message);
    }
  };
  const handleChangePassword = async () => {
    setIsLoading(true);
    try {
      const payload = {
        oldPassword: form.oldPassword,
        newPassword: form.newPassword,
        confirmPassword: form.confirmPassword,
      };
      setFormError({});
      await updateSubAdminPasswordValidation.validate(payload, {
        abortEarly: false,
      });
      const res = await api.changePassword(payload);
      const { status } = res;
      if (status) {
        console.log(res, "Res....");
        setForm({ email: "", oldPassword: "", newPassword: "" });
        toast.success("Password Update Successfully");
        setIsLoading(false);
      } else {
        toast.error(res?.error?.message);
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
        setIsLoading(false);
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        console.log(error, "error");
        toast.error(error.message);
        setIsLoading(false);
      }

      setIsLoading(false);
    }
  };
  return (
    <>
      {isLoading && <Spinner />}
      <div>
        <form>
          <header>
            <h4>Settings</h4>
          </header>
          <div className="max-w-xl mx-auto grid grid-cols-1 gap-6 mt-4">
            <div className=" items-center gap-3 hidden">
              <div className="input_field">
                <input
                  type="text"
                  placeholder="Email Address"
                  name="email"
                  onChange={handleChange}
                />
              </div>
              <div className="">
                <button
                  type="button"
                  className="red-btn"
                  onClick={handleChangeEmail}
                >
                  {" "}
                  Update
                </button>
              </div>
            </div>
            <div className="flex flex-col items-center gap-3">
              <div className="w-full mb-3 text-center">
                <h5>Change Password</h5>
              </div>
              <div className="w-full mb-3">
                <div className="input_field relative">
                  <input
                    type={eye ? "text" : "password"}
                    placeholder="Old Password"
                    name="oldPassword"
                    onChange={handleChange}
                  />

                  <span
                    className="absolute right-4 top-[50%] translate-y-[-50%] cursor-pointer z-10"
                    onClick={() => setEye(!eye)}
                  >
                    {eye ? (
                      <AiFillEye className="text-2xl text-theme-text" />
                    ) : (
                      <AiFillEyeInvisible className="text-2xl text-theme-text" />
                    )}
                  </span>
                </div>
                {formError.oldPassword && (
                  <div className="form-eror">{formError.oldPassword}</div>
                )}
              </div>
              <div className="w-full mb-3">
                <div className="input_field relative">
                  <input
                    type={eyeNew ? "text" : "password"}
                    placeholder="New Password"
                    name="newPassword"
                    onChange={handleChange}
                  />

                  <span
                    className="absolute right-4 top-[50%] translate-y-[-50%] cursor-pointer z-10"
                    onClick={() => setEyeNew(!eyeNew)}
                  >
                    {eyeNew ? (
                      <AiFillEye className="text-2xl text-theme-text" />
                    ) : (
                      <AiFillEyeInvisible className="text-2xl text-theme-text" />
                    )}
                  </span>
                </div>
                {formError.newPassword && (
                  <div className="form-eror">{formError.newPassword}</div>
                )}
              </div>
              <div className="w-full mb-3">
                <div className="input_field relative">
                  <input
                    type={eyeNewNew ? "text" : "password"}
                    placeholder="Confirm New Password"
                    name="confirmNewPassword"
                    onChange={handleChange}
                  />

                  <span
                    className="absolute right-4 top-[50%] translate-y-[-50%] cursor-pointer z-10"
                    onClick={() => setEyeNewNew(!eyeNewNew)}
                  >
                    {eyeNewNew ? (
                      <AiFillEye className="text-2xl text-theme-text" />
                    ) : (
                      <AiFillEyeInvisible className="text-2xl text-theme-text" />
                    )}
                  </span>
                </div>
                {formError.confirmNewPassword && (
                  <div className="form-eror">
                    {formError.confirmNewPassword}
                  </div>
                )}
              </div>
            </div>
            <div className="text-right">
              <button
                type="button"
                className="red-btn"
                onClick={handleChangePassword}
              >
                {" "}
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Setting;
