import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { addBuyersValidation } from "../../utils/validation";
import * as api from "../../redux/api";
import { toast } from "react-toastify";
import { isYupError, parseYupError } from "../../utils/Yup";
const initialState = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangeSubAdminPassword = ({ handleClose, open }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [formError, setFormError] = useState(initialState);
  const handleCloseModal = () => {
    handleClose();
    setForm(initialState);
    setFormError({});
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };
  console.log(form, "form");

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      setFormError({});
      await addBuyersValidation.validate(form, {
        abortEarly: false,
      });
      const res = await api.addtoBuyer(form);
      if (res.status === 200 || res.status === 201) {
        setIsLoading(false);
        toast.success("Added Successfully");
        handleCloseModal();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
        setIsLoading(false);
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        console.log(error, "error");
        toast.error(error.message);
        setIsLoading(false);
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <h4 className=" text-center">Change Password</h4>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <div>
            <div className="max-w-4xl ">
              <div className="w-full grid grid-cols-2 gap-6 mt-4">
                <div className="input_field">
                  <input
                    type="text"
                    placeholder="Enter old password"
                    name="oldPassword"
                    onChange={handleChange}
                  />
                  {formError.oldPassword && (
                    <div className="form-eror">{formError.oldPassword}</div>
                  )}
                </div>
                <div className="input_field">
                  <input
                    type="text"
                    placeholder="Enter new password"
                    name="newPassword"
                    onChange={handleChange}
                  />
                  {formError.newPassword && (
                    <div className="form-eror">{formError.newPassword}</div>
                  )}
                </div>
                <div className="input_field col-span-2">
                  <input
                    type="text"
                    placeholder="Enter confirm password"
                    name="confirmPassword"
                    onChange={handleChange}
                  />
                  {formError.confirmPassword && (
                    <div className="form-eror">{formError.confirmPassword}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={handleCloseModal}
            className={`btn btn-danger `}
          >
            Close
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            disabled={isLoading}
            className={`btn btn-primary ${
              isLoading
                ? "bg-red-500 border-red-500 opacity-50 disabled:pointer-events-none"
                : undefined
            }`}
          >
            {isLoading ? "Loading" : "Submit"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeSubAdminPassword;
