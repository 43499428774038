import * as yup from "yup";

export const signUpValidation = yup.object().shape({
  email: yup
    .string()
    .required("Please enter you email address")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
});
export const loginValidation = yup.object().shape({
  emailOrMobile: yup
    .string()
    .required("Please enter your email or mobile")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  password: yup
    .string()
    .required("Please enter password.")
    .matches(
      /^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/,
      "Only accept One Uppercase and atleast one special characters and numbers."
    )
    .min(8, "Minimum 8 characters is required."),
  // verificationCode: yup.string().required('Please enter OTP'),
});
export const subAdminValidation = yup.object().shape({
  fullname: yup.string().required("Please enter you full name"),
  email: yup
    .string()
    .required("Please enter you email address")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  password: yup
    .string()
    .required("Please enter password.")
    .matches(
      /^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/,
      "Only accept One Uppercase and atleast one special characters and numbers."
    )
    .min(8, "Minimum 8 characters is required."),
});
export const addBuyersValidation = yup.object().shape({
  firstname: yup.string().required("Please enter your first name"),
  lastname: yup.string().required("Please enter your last name"),
  address: yup.string().required("Please enter your wallet address"),
  fractionToBuy: yup
    .string()
    .required("Please enter Fractions")
    .test("is-positive", "Fraction must be a positive number", (value) => {
      return value && Number(value) >= 0;
    }),
  totalPrice: yup.string().required("Please enter total price of fraction"),
  email: yup
    .string()
    .required("Please enter email address")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  mobile: yup
    .string()
    .required("Please enter mobile number")
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please enter only 10 digit number."
    ),
  password: yup
    .string()
    .required("Please enter password")
    .matches(
      /^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/,
      "Only accept One Uppercase and atleast one special characters and numbers."
    )
    .min(8, "Minimum 8 characters is required"),
});

export const currencyValidation = yup.object().shape({
  currency: yup.string().required("Required"),
  currentRate: yup.string().required("Required"),
  agentCommission: yup
    .number()
    .positive()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required"),
  enhancer: yup
    .number()
    .positive()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required"),
  platformFee: yup
    .number()
    .positive()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required"),
});
export const userValidation = yup.object({
  fullname: yup.string().required("Full name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please enter only 10 digit number."
    ),
  city: yup.string().nullable(),
  country: yup.string().nullable(),
  whatsapp: yup
    .string()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please enter only 10 digit number."
    )
    .nullable(),
  telegram: yup.string().nullable(),
});
export const partnerValidation = yup.object({
  fullname: yup.string().required("Full name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please enter only 10 digit number."
    ),
  city: yup.string().required("City is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
  timeZone: yup.string().required("TimeZone is required"),
  whatsapp: yup
    .string()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please enter only 10 digit number."
    )
    .nullable(),
  telegram: yup.string().required("Telegram is required"),
});
export const QrCodeValidation = yup.object().shape({
  upi: yup.string().required("Required"),
  image: yup.string().required("Required"),
});

export const AssignPartnerValidation = yup.object().shape({
  partnerId: yup.string().required("Required"),
});

export const AccountValidation = yup.object().shape({
  accountHolderName: yup.string().required("Required"),
  accountNumber: yup
    .string()
    .matches(/^[0-9]{9,18}$/, "Invalid account number")
    .required("Required"),
  bankName: yup.string().required("Required"),
  ifscCode: yup
    .string()
    .matches(/^[A-Za-z]{4}[0-9]{7}$/, "Invalid IFSC code")
    .required("Required"),
});

export const merchantValidationSchema = yup.object().shape({
  fullname: yup.string().required("Full name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  websiteDomain: yup.string().required("Website Domain is required"),
  platformName: yup.string().required("Platform Name is required"),
  callbackUrl: yup.string().required("Call Back Url is required"),
  uin: yup.string().required("uin is required"),
  walletAddress: yup.string().required("Wallet Address is required"),
  howToFind: yup.string().required("How to find is required"),
});
export const updateSubAdminPasswordValidation = yup.object().shape({
  oldPassword: yup
    .string()
    .required("Please enter old password")
    .matches(
      /^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/,
      "Only accept One Uppercase and at least one special character and number."
    )
    .min(8, "Minimum 8 characters is required"),

  newPassword: yup
    .string()
    .required("Please enter new password")
    .matches(
      /^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/,
      "Only accept One Uppercase and at least one special character and number."
    )
    .min(8, "Minimum 8 characters is required"),

  confirmNewPassword: yup
    .string()
    .required("Please enter confirm new password")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .matches(
      /^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/,
      "Only accept One Uppercase and at least one special character and number."
    )
    .min(8, "Minimum 8 characters is required"),
});
