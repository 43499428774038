import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";
import { postReq } from "../../utils/apiHandlers";
import Cookies from "js-cookie";

export const login = createAsyncThunk(
  "auth/login",
  async ({ form, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await postReq("/auth/login", form);
      const { status } = response;
      if (status) {
        // Cookies.set('accessToken', response.data.accessToken);
        localStorage.setItem("accessToken", response.data.accessToken);
        Cookies.set(`${process.env.REACT_APP_NETWORK}accessToken`, true);
        toast.success("Login Successfully");
        navigate("/dashboard");
      } else {
        toast.error(response.error.message);
      }
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message);
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async ({ email, setStep, toast }, { rejectWithValue }) => {
    try {
      const response = await api.signUp(email);
      toast.success("Sent Code Successfully");
      setStep(2);
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message);
      return rejectWithValue(err.response.data);
    }
  }
);
export const getAuth = createAsyncThunk("auth/data", async () => {
  try {
    const response = await api.getAdmin();
    return response.data;
  } catch (err) {
    return err;
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    error: "",
    loading: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLogout: (state) => {
      state.user = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAuth.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getAuth.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export const { setUser, setLogout } = authSlice.actions;

export default authSlice.reducer;
