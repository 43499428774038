import React, { useEffect, useState } from "react";
import { FiAward } from "react-icons/fi";
import "react-horizontal-scrolling-menu/dist/styles.css";
// import CardDetail from "./Cards/CardDetail";
// import { reactIcons } from "../../utils/icons";
// import DashboardCard from "./Cards/DashboardCard";
// import * as api from "../../redux/api";
import Loader from "../../components/loaders/Loader";
// import { toast } from "react-toastify";

const DashboardLanding = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [stages, setStages] = useState([]);
  // const [cardData, setCardData] = useState({
  //   status: false,
  //   data: "",
  // });
  // const handleClick = (item) => {
  //   if (item) {
  //     setCardData({ status: true, data: item });
  //   }
  // };
  const getAllStages = async () => {
    setIsLoading(true);
    // const res = await api.getStages();
    // const { status, data } = res;
    // if (status >= 200 && status < 300) {
    //   setStages(data?.data);
    //   setIsLoading(false);
    // } else {
    //   setIsLoading(false);
    //   toast.error(data.message);
    // }
  };
  useEffect(() => {
    getAllStages();
  }, []);
  return (
    <>
      {/* {isLoading && <Loader />} */}
      <div>
        <div className="upper flex flex-col gap-4">
          <h4 className="flex items-center gap-2 mb-4">
            Dashboard <FiAward className="text-[22px] text-red-600" />{" "}
          </h4>
        </div>
        {/* {cardData.status && (
          <button
            className="bg-black px-1 py-1 rounded-lg text-white text-24"
            onClick={() => setCardData({ status: false })}
          >
            {reactIcons.arrowleft}
          </button>
        )} */}
        {/* {!cardData.status && stages.length > 0 && (
          <DashboardCard data={stages} handleClick={handleClick} />
        )}
        {cardData.status && <CardDetail singleData={cardData.data} />} */}
      </div>
    </>
  );
};
export default DashboardLanding;
