import React, { useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import { reactIcons } from "../utils/icons";
import CustomNoRowsOverlay from "../utils/styles";
import * as api from "../redux/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const staticData = [
  {
    id: 1,
    date: "2024-10-01",
    time: "10:00 AM",
    validateBook: "Book 1",
    recieveReward: "5 ETH",
  },
  {
    id: 2,
    date: "2024-10-02",
    time: "11:30 AM",
    validateBook: "Book 2",
    recieveReward: "3 ETH",
  },
  {
    id: 3,
    date: "2024-10-03",
    time: "12:15 PM",
    validateBook: "Book 3",
    recieveReward: "7 ETH",
  },
  {
    id: 4,
    date: "2024-10-04",
    time: "02:45 PM",
    validateBook: "Book 4",
    recieveReward: "4 ETH",
  },
  {
    id: 5,
    date: "2024-10-05",
    time: "04:00 PM",
    validateBook: "Book 5",
    recieveReward: "6 ETH",
  },
];
const ReportStepOne = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [rowCountState, setRowCountState] = useState(0);

  const handleView = (data) => {
    navigate(`/report-second/${data.id}`);
  };

  const [rows, setRows] = useState(staticData);

  const columns = [
    // {
    //   field: "date",
    //   headerName: "Date",
    //   type: "string",
    //   flex: 3,
    // },
    {
      field: "time",
      headerName: "Time",
      type: "string",
      flex: 3,
    },

    {
      field: "validateBook",
      headerName: "Validate Block",
      type: "string",
      flex: 3,
    },
    {
      field: "recieveReward",
      headerName: "Recieve Reward",
      type: "string",
      flex: 3,
    },
    {
      field: "actions",
      headerName: "View",
      type: "actions",
      flex: 1,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={reactIcons.eyeVisible}
            label="View"
            onClick={() => handleView(params.row)}
          />,
        ];
      },
    },
  ];
  const getSubAdminsData = async () => {
    setIsLoading(true);
    const response = await api.getSubAdmins(paginationModel.page);

    const { status, data } = response;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data.count);
      setRows(
        data.subAdmins.map((item, index) => {
          return { ...item, _id: 8 * paginationModel.page + index + 1 };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  useEffect(() => {
    try {
      getSubAdminsData();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }

    setRowCountState((prevRowCountState) =>
      rowCountState !== undefined ? rowCountState : prevRowCountState
    );
  }, [paginationModel, rowCountState, setRowCountState]);
  return (
    <div className="w-full mt-6 data-grid">
      <div className="flex gap-2 mb-6 items-center justify-between">
        <div className="flex items-center gap-1">
          <button
            className="w-10 h-10 flex-center bg-theme-lighter rounded-full text-3xl"
            onClick={() => navigate(-1)}
          >
            {" "}
            {reactIcons.arrowleft}
          </button>
          <h4>Reports</h4>
        </div>
        <div className="text-[19px] border border-[#f2f2f2] p-[5px] h-[45px] flex items-center justify-center w-[200px] rounded-[7px]">
          15/09/2024
        </div>
      </div>

      <div className=" h-[600px] w-full">
        <DataGrid
          // loading={isLoading}
          rows={rows}
          getEstimatedRowHeight={() => 52}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          rowCount={rowCountState}
          pageSizeOptions={[8]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableColumnMenu
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
};

export default ReportStepOne;
