import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import "animate.css";
import { Fragment, useEffect } from "react";
import PrivateRoute from "./PrivateRoute";
import { getAuth } from "./redux/features/authSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { sideBarLinks } from "./utils/constants";
import NotFound from "./pages/NotFound";
import DashboardLanding from "./pages/Dashboard/DashboardLanding";
import ReportStepTwo from "./pages/ReportStepTwo";
import ReportStepOne from "./pages/ReportStepOne";

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const setAuth = async (e) => {
      try {
        dispatch(getAuth());
      } catch (error) {}
    };
    if (Cookies.get(`${process.env.REACT_APP_NETWORK}accessToken`) === "true") {
      setAuth();
    }
  }, [dispatch]);
  const navigate = useNavigate();
  useEffect(() => {
    const cookeiData = Cookies.get(
      `${process.env.REACT_APP_NETWORK}accessToken`
    );
    if (!cookeiData) {
      navigate("/");
    }
  }, [navigate]);
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route index path="dashboard" element={<DashboardLanding />}></Route>
          <Route
            path="report-first/:userId"
            element={<ReportStepOne />}
          ></Route>
          <Route
            path="report-second/:userId"
            element={<ReportStepTwo />}
          ></Route>
          {sideBarLinks.map(
            (item, index) =>
              item.title !== "Dashboard" && (
                <Route
                  key={index}
                  path={item.path}
                  element={<Fragment>{item.component}</Fragment>}
                ></Route>
              )
          )}

          {/* <Route path="bonus/:level" element={<LevelBonus />}></Route>
          <Route path="profile/:userId" element={<Profile />}></Route>
          <Route path="privileges/:subAdminId" element={<Privilege />}></Route>
          <Route path="create-subadmin" element={<CreateSubAdmin />}></Route>
          <Route
            path="referral-users/:level/:userId/:address"
            element={<ReferralUsers />}
          ></Route> */}
          <Route path="*" element={<NotFound />}></Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
