import React, { useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import { reactIcons } from "../utils/icons";
import CustomNoRowsOverlay from "../utils/styles";
import * as api from "../redux/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DateRangePickerCustom from "../components/Forms/DateRangePickerCustom";
import moment from "moment";
const staticData = [
  {
    id: 1,
    user_name: "John Doe",
    nodeId: "0xabc123",
    noOfBlock: 120,
    accumulateReward: "10 ETH",
    createdAt: "2024-09-15T08:30:00Z",
  },
  {
    id: 2,
    user_name: "Jane Smith",
    nodeId: "0xdef456",
    noOfBlock: 85,
    accumulateReward: "5 ETH",
    createdAt: "2024-09-15T08:30:00Z",
  },
  {
    id: 3,
    user_name: "Bob Johnson",
    nodeId: "0xghi789",
    noOfBlock: 150,
    accumulateReward: "12 ETH",
    createdAt: "2024-09-15T08:30:00Z",
  },
  {
    id: 4,
    user_name: "Alice Brown",
    nodeId: "0xjkl101",
    noOfBlock: 110,
    accumulateReward: "8 ETH",
    createdAt: "2024-09-15T08:30:00Z",
  },
  {
    id: 5,
    user_name: "Charlie Green",
    nodeId: "0xmnop112",
    noOfBlock: 95,
    accumulateReward: "6 ETH",
    createdAt: "2024-09-15T08:30:00Z",
  },
];
const Reports = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [searchText, setSearchText] = useState("");
  const [rowCountState, setRowCountState] = useState(0);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf("month").toDate());
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleView = (data) => {
    navigate(`/report-first/${data.id}`);
  };

  const [rows, setRows] = useState(staticData);

  const columns = [
    {
      field: "createdAt",
      headerName: "Created At",
      type: "dateTime",
      flex: 2,
      valueGetter: (params) => {
        // Ensure the value is a Date object or null
        return params.row.createdAt ? new Date(params.row.createdAt) : null;
      },
      renderCell: (params) =>
        params.value ? moment(params.value).format("DD/MM/YYYY") : "",
    },
    {
      field: "user_name",
      headerName: "User Name",
      type: "string",
      flex: 3,
    },
    {
      field: "nodeId",
      headerName: "Node ID",
      type: "string",
      flex: 3,
    },

    {
      field: "noOfBlock",
      headerName: "Number of Block Validate",
      type: "string",
      headerAlign: "center",
      align: "center",
      flex: 3,
    },
    {
      field: "accumulateReward",
      headerName: "Accumulated Reward",
      type: "string",
      headerAlign: "center",
      align: "center",
      flex: 3,
    },
    {
      field: "actions",
      headerName: "View",
      type: "actions",
      flex: 1,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={reactIcons.eyeVisible}
            label="View"
            onClick={() => handleView(params.row)}
          />,
        ];
      },
    },
  ];
  const getSubAdminsData = async () => {
    setIsLoading(true);
    // const response = await api.getSubAdmins(paginationModel.page);

    // const { status, data } = response;
    // if (status >= 200 && status < 300) {
    //   setIsLoading(false);
    //   setRowCountState(data.count);
    //   setRows(
    //     data.subAdmins.map((item, index) => {
    //       return { ...item, _id: 8 * paginationModel.page + index + 1 };
    //     })
    //   );
    // } else {
    //   setIsLoading(false);
    //   toast.error(data.message);
    // }
  };
  useEffect(() => {
    try {
      getSubAdminsData();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }

    setRowCountState((prevRowCountState) =>
      rowCountState !== undefined ? rowCountState : prevRowCountState
    );
  }, [paginationModel, rowCountState, setRowCountState]);
  return (
    <div className="w-full mt-6 data-grid">
      <div className="flex gap-2 justify-between mb-6 items-center">
        <h4>Reports</h4>
        <div className="flex items-center gap-2">
          <div className="flex flex-row items-center gap-2 justify-end w-full xl:w-auto">
            <p className="text-black text-16">Date</p>
            <div className="w-full lg:w-[270px] border border-black rounded-lg">
              <DateRangePickerCustom
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
          <div className="relative">
            <input
              className="w-[250px] h-[45px]"
              type="text"
              value={searchText}
              placeholder="Search by name"
              onChange={(e) => setSearchText(e.target.value)}
            />
            <span className="absolute text-18 top-[calc(50%-9px)] right-2">
              {reactIcons.search}
            </span>
          </div>
        </div>
      </div>

      <div className=" h-[600px] w-full">
        <DataGrid
          // loading={isLoading}
          rows={rows}
          getEstimatedRowHeight={() => 52}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          rowCount={rowCountState}
          pageSizeOptions={[8]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableColumnMenu
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
};

export default Reports;
