import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../redux/features/authSlice";
import { loginValidation } from "./../utils/validation";
import { isYupError, parseYupError } from "./../utils/Yup";
import { toast } from "react-toastify";
import Loader from "../components/loaders/Loader";

const Login = () => {
  const dispatch = useDispatch();
  const [eye, setEye] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForm] = useState({
    emailOrMobile: "",
    password: "",
  });

  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    try {
      setFormError({});
      setIsLoading(true);
      await loginValidation.validate(form, { abortEarly: false });
      await dispatch(login({ form, navigate, toast }));
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };

  return (
    <div className=" min-h-screen w-full flex justify-center bg-opacity-50 bg-blue-50 items-center">
      <div className="shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px]    bg-white p-14 flex flex-col items-center rounded-xl max-w-[550px] w-full">
        <h2 className="text-red-600  font-black  text-center">Login Page</h2>
        <span className="text-[18px] mt-2 font-medium text-theme-text ">
          Sign Into Account
        </span>

        <form
          className="w-full flex flex-col gap-6 mt-4"
          onSubmit={handleLogin}
        >
          <div className="input_field">
            <input
              type="text"
              placeholder="Email Address or Mobile"
              name="emailOrMobile"
              value={form.emailOrMobile}
              onChange={handleChange}
            />
            {formError.emailOrMobile && (
              <div className="form-eror">{formError.emailOrMobile}</div>
            )}
          </div>
          <div>
            <div className="input_field relative">
              <input
                type={eye ? "text" : "password"}
                placeholder="Password"
                name="password"
                onChange={handleChange}
              />

              <span
                className="absolute right-4 top-[50%] translate-y-[-50%] cursor-pointer z-10"
                onClick={() => setEye(!eye)}
              >
                {eye ? (
                  <AiFillEye className="text-2xl text-theme-text" />
                ) : (
                  <AiFillEyeInvisible className="text-2xl text-theme-text" />
                )}
              </span>
            </div>
            {formError.password && (
              <div className="form-eror">{formError.password}</div>
            )}
          </div>

          <button
            type="submit"
            className="text-white py-4 rounded-lg bg-red-600 w-full max-w-[100%] uppercase  text-[18px] "
          >
            {isLoading ? <Loader /> : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
